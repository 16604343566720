<template>
  <a-statistic class="cs-money" v-bind="Object.assign(attrs, $attrs)">
    <template #prefix>
      <cs-icon name="icon-qian" size="14" />
    </template>
  </a-statistic>
</template>
<script>
export default {
  data() {
    return {
      attrs: {
        precision: 2,
      },
    };
  },
};
</script>
<style lang="less" scoped>
.cs-money {
  /deep/ .ant-statistic-content {
    color: #606266;
    line-height: 16px;
    .ant-statistic-content-value-int,
    .ant-statistic-content-value-decimal {
      font-size: 16px;
      margin-top: -2px;
    }
  }
}
</style>